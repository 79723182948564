/* Testimonials container styling */
.testimonials-container {
  padding: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: visible;
}

.testimonials-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: visible;
}

.testimonials-item {
  padding: 12px 8px 24px;
  overflow: visible;
}

/* Individual testimonial card styling */
.testimonial-card {
  margin-bottom: 8px;
  border-radius: 12px;
  overflow: visible;
  padding: 24px;
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.testimonial-avatar {
  margin-right: 20px;
  border: 3px solid #f0f0f0;
}

.testimonial-author {
  display: flex;
  flex-direction: column;
}

.testimonial-name {
  font-size: 18px;
  margin-bottom: 4px;
}

.testimonial-role {
  font-size: 14px;
  margin-bottom: 6px;
}

.testimonial-quote-container {
  margin-top: 16px;
}

.testimonial-intro {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  color: var(--main-color);
}

.testimonial-quote {
  font-style: italic;
  color: #555;
  font-size: 16px;
  line-height: 1.6;
}

/* Media queries for responsive design */
@media (max-width: 767px) {
  /* Mobile view adjustments */
  .testimonials-container {
    padding: 16px 12px;
  }
  
  .testimonials-item {
    padding: 8px 4px 16px;
  }
  
  .testimonial-card {
    padding: 0px;
  }
  
  .testimonial-header {
    flex-direction: column;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .testimonial-avatar {
    margin-right: 0;
    margin-bottom: 16px;
    width: 70px;
    height: 70px;
  }
  
  .testimonial-author {
    align-items: center;
  }
  
  .testimonial-intro {
    font-size: 16px;
    text-align: center;
  }
  
  .testimonial-quote {
    font-size: 15px;
  }
} 