:root {
  /* global variables */
  --main-color: #008B8B;
  --background-color: #f5f5f5;
  --hover-color: #33A6A6;
  --text-background-color: #F5F5F5; /* off-white */
  --text-color: #333333; /* dark grey */

  /* global settings */
  color: var(--main-color);
  background-color: var(--background-color);
  min-height: 100vh;
  margin: 0;
}

/* ensure that no element can cause the viewport to expand unintentionally */
body {
  max-width: 100vw;
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* Making sure footer is automatically pinned to the bottom of the page */
.layout {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  min-height: 103vh;
}

.header {
  text-align: center;
  background-color: var(--background-color);
}

.site-layout-content {
  flex: 1;
  overflow: auto;
}

a {
  color: var(--main-color);
  text-decoration: none; /* Optional: Removes underline from links */
}

.iconSpacing {
  margin-right: 4px;
}

.rotated-icon {
  transform: rotate(90deg);
  display: inline-block;
}
.fixed-text {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 480px;      /* Adjust this value based on your layout needs */
  left: 0;
  right: 0;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;   /* Optional, for better text presentation */
  z-index: 1000;   /* Ensures the text stays above other content */

  span {
    margin-bottom: 3px;
  }
}

.app-nav {
  padding-top: 20px;
  margin-top: 30px;
}

.app-nav a {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-right: 20px;
}

.app-nav--sessions {
  float: right;
}

.app-nav--sessions svg {
  margin-right: 5px;
}

.app-nav a {
  color: var(--main-color);
  text-decoration: none;
  font-size: 1rem;
  margin: 0 1rem;
}

.page {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
}

.public-queries-page {
  max-width: 820px;
  margin: 0 auto;
}

.public-query-share-page {
  max-width: 820px;
  margin: 0 auto;
}

/* Change the link color to grey */
.recent-queries-card a,
.recent-queries-drawer a {
  color: #808080; /* grey color */
}

.recent-queries-card a:hover,
.recent-queries-drawer a:hover {
  color: #606060; /* darker grey on hover */
}

.query-card-content {
  h2 {
    font-size: 1.15rem;
  }
  h3 {
    font-size: 0.9rem;
  }
}

.example-question-button {
  transition: color 0.3s ease, font-weight 0.3s ease;
}

.example-question-button:hover .ant-typography {
  color: #000;
}

.sign-up-page {
  display: flex;
  flex-direction: row;
  align-items: center; /* Change from centering everything to only aligning items if needed */
  padding-top: 10px;
  width: 100%; /* Ensure it takes full width */
  margin: 0 auto; /* Center the layout */
}

.sign-up-page--row {
  display: flex;
  flex-wrap: wrap;  /* Allows multiple lines of items */
  align-items: stretch;  /* Makes all flex children (columns) the same height */
}

.sign-up-page--legal.ant-typography {
  color: #b3b3b3; /* Grey color */
}

.sign-up-page--legal a {
  text-decoration: underline;
  color: inherit; /* Inherit color from parent (.sign-up-page--legal) */
}

.marketing-info {
  max-width: 450px;  /* Limiting the width to 300px */
  width: 100%;       /* Ensuring it takes up all space up to 300px */
  margin: 0 auto;    /* Centering the input within the column */
}

.marketing-info--header {
  margin: 20px 20px;
  text-align: center;
  color: var(--text-color);
}

.form-sizing {
  max-width: 350px;  /* Limiting the width to 300px */
  width: 100%;       /* Ensuring it takes up all space up to 300px */
  margin: 0 auto;    /* Centering the input within the column */
  button {
    width: 100%;
  }
}

.queries--header {
  margin-bottom: 10px;
  text-align: center;
  color: var(--text-color);
}

/* Sessions */
.sessions {
  width: 100%;
  list-style: none;
  padding: 0;
  font-size: 0.6rem;
  overflow-y: auto;
}

.session-insights {
  ul {
    padding-left: 15px;
  }
}

.session-notepad {
  ul {
    padding-left: 15px;
  }
}

.sessions--session {
  color: var(--main-color);
  font-family: Arial, sans-serif;
  transition: background-color 0.3s;
  border-radius: 8px;
  font-size: 18px;
}

.sessions--session:first-of-type {
  padding-top: 0;
}

.sessions--session a {
  color: var(--main-color);
}

.sessions--session:hover a {
  color: #444;
}

.sessions--session:hover {
  background-color: #e9e9e9;
  color: #444;
}

.sessions--note-preview {
  font-size: 16px;
  opacity: 0.5;
}

/* Session Details */

.session-details {
  list-style: none;
  padding: 0;
  font-size: 0.6rem;
  overflow-y: auto;

  /* making the buttons align more to the right */
  .ant-card-head {
    padding: 0 6px;
  }

  .ant-card-body {
    padding: 20px;
  }
}

.session-details--header {
  margin-top: 20px;
  text-align: center;
  color: var(--text-color);
}

/* Reduce gap between tabs and their content */
.note-details--tabs .ant-tabs-content {
  margin-top: -16px; /* Adjust this value to remove the gap */
}

.note-details--tab-content {
  margin: 0 !important; /* Ensure there's no additional margin */
  padding: 0 !important; /* Ensure there's no additional padding */
}

/*  Apply tabs border (in the same color as the NoteCard border) */
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  border-color: #E0E0E0;
  border-width: 1px;
}

.session-details--buttons-container {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */

  button {
    margin-right: 5px;
  }
}

.session-details--note-empty {
  text-align: center;
  font-size: medium;
}

.session-details--note {
  background-color: var(--text-background-color);
  color: var(--text-color);
  margin: 20px 20px;
  border-radius: 8px;
  padding: 20px;
}

/* Session Recorder */

.session-recorder-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 3rem;
  color: var(--main-color);
}

.session-recorder {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}

.session-recorder-top {
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: end;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.session-recorder--modal {
  min-width: 580px;
  top: 50px;
  padding: 30px 0px;
}

.session-recorder--note {
  padding-top: 20px;
}

.session-recorder--timer {
  font-size: 2rem;
  color: var(--main-color);
  align-self: center;
  opacity: 0.8;
  padding: 15px 0;
}

.session-recorder button {
  margin-top: 30px;

  .iconAlign {
    vertical-align: middle;
  }
}

.session-recorder button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.session-recorder--save, .session-recorder--toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.session-recorder--save p, .session-recorder--toggle p {
  font-size: 1rem;
  margin-left: 8px;
}


/* Custom Ant Styles */

.ant-modal-confirm-title, .ant-modal-header {
  text-align: center;
}

/****************** Responsive treatments *******************/
@media (max-width: 767px) {
  .sign-up-page--legal {
    font-size: x-small; /* Adjust the font size as needed */
  }

  .form-sizing {
    max-width: 300px;  /* Limiting the width to 300px */
    margin: 0 auto;    /* Centering the input within the column */
    button {
      width: 100%;
    }
  }

  .session-recorder--modal {
    min-width: 100px;
  }
  /* add more here */
}

/* Add this to your existing CSS */
.ant-layout-sider {
  transition: all 0.2s;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.ant-btn-primary {
  box-shadow: 'rgba(31, 86, 86, 1) 0px 3px 8px';
}

/* Dark mode styles */
.html-dark {
  filter: grayscale(100%) invert(100%);
}

.html-dark .ant-layout-sider-dark {
  filter: invert(1);
}

/* Styles for the dark mode toggle switch */
.ant-switch-checked {
  background-color: #1890ff;
}

.ant-switch-handle::before {
  background-color: #fff;
}

/* Ensure the toggle is visible in both light and dark modes */
.html-dark .ant-switch {
  filter: invert(1);
}

.call-details-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.call-header {
  margin-bottom: 24px;
}

.call-meta {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  margin-top: 16px;
}

.call-meta p {
  margin: 8px 0;
}

.call-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.transcript-section, .note-section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* ensure font size large enough so that mobile zoom isn't triggered for select with search */
.ant-select-selection-search input {
  font-size: 16px;
}

/* make the flag in the phone-input unclickable */
.react-international-phone-country-selector-button {
  pointer-events: none; /* Disable interaction with the button */
  cursor: default; /* Set the cursor to default to indicate it's not clickable */
}
 /* disable the drop-down in country selector */
.react-international-phone-country-selector-button__button-content {
  pointer-events: none; /* Disable interaction */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
}

.react-international-phone-country-selector-button__dropdown-arrow {
  display: none; /* Hide the dropdown arrow */
}

/* When a file is dragged over, change the entire background to a stronger highlight */
.ant-upload-drag-hover {
  border-color: #1890ff !important;
  background-color: #e6f7ff !important; /* a more pronounced blue background */
}