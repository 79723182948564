/* Responsive styles for the Sign Up page */
.signup-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  padding: 24px;
}

.signup-row {
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 600px;
}

.form-column {
  height: 100%;
  min-height: 600px;
  position: relative;
}

.form-container {
  padding: 38px;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}

.form-title {
  text-align: center;
  margin-bottom: 24px;
  font-size: 24px;
}

.legal-text {
  font-size: 12px;
  line-height: 1.5;
  display: block;
  margin-top: 16px;
}

.testimonial-column {
  height: 100%;
  min-height: 600px;
  padding: 20px;
}

.security-card {
  text-align: center;
  margin: 32px 0;
  padding: 24px 24px 24px 24px;
  border-radius: 8px;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  width: 100%;
  max-width: 1200px;
}

.security-icon {
  font-size: 32px;
  color: #52c41a;
  margin-bottom: 16px;
}

.security-title {
  margin-top: 5px;
}

/* Media queries for responsive layouts */
@media (max-width: 767px) {
  /* Mobile view */
  .signup-row {
    border-radius: 8px;
  }
  
  .form-column {
    min-height: auto;
  }
  
  .form-container {
    padding: 24px;
  }
  
  .testimonial-column {
    padding: 30px 20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Tablet view */
  .form-column {
    min-height: 550px;
  }
  
  .form-container {
    padding: 30px;
  }
  
  .testimonial-column {
    padding: 30px 25px;
  }
} 